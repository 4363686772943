/* src/styles/CookieConsent.css */
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  text-align: center;
  z-index: 1000;
}

.cookie-consent-banner p {
  margin: 0;
  padding: 0 1rem;
}

.cookie-consent-banner button {
  margin: 0.5rem 0.5rem 0 0.5rem; /* Ajusta el margen superior */
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.cookie-consent-banner button:hover {
  background-color: #0056b3;
}

.preferences-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.preferences-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.preferences-content label {
  display: block;
  margin: 1rem 0;
}

.preferences-content button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.preferences-content button:hover {
  background-color: #0056b3;
}

