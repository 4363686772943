.images-grid-container {
    column-count: 2;
    column-gap: 10px;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
}

.image-container {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

@media (max-width: 576px) {
    .images-grid-container {
        column-count: 1;
    }
}

.imagen-grid {
    width: 100%;
    height: auto;
    display: block;
    transition: all 0.3s ease;
}

.image-container:hover .imagen-grid {
    filter: brightness(50%);
    transform: scale(0.992);
}

.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.image-container:hover .image-text {
    opacity: 1;
}

.titulo-img-grid {
    font-weight: 500;
    text-transform: uppercase;
}

.year-img-grid {
    font-weight: 300;
}






/* .images-grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 90vw;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-left: 5vw;
    margin-right: 5vw;
}

@media (max-width: 576px){
 .images-grid-container{
    grid-template-columns: 1fr;
 }
}

.imagen-grid{
    width: 100%;
}

.image-container {
    position: relative;
    overflow: hidden;
}

.imagen-grid {
    display: block;
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
}

.image-container:hover .imagen-grid {
    filter: brightness(50%);
    transform: scale(0.992);

}

.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.image-container:hover .image-text {
    opacity: 1;
}

.navcontainer{
    z-index: 1;
}

.navbarra{
    position: fixed;
}

.titulo-img-grid{
    font-weight: 500;
    text-transform: uppercase;
}

.year-img-grid{
    font-weight: 300;
}
 */
