:root {
	--mainColor: #fff;
	--mainColorLight: #5767aa;
	--secondaryColor: rgb(170, 201, 114);
	--textColor: #6b6b6b;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
	margin-bottom: 0;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
		position: absolute;
		z-index: 2;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
  nav Link {
		font-size: 1.5rem;
	}
}

.logo{
	height: 40px;
	margin-right: auto;
	margin-left: 4vw;
	margin-top: 3.5vh;
	font-family: 'Nanum Gothic', serif;
	font-size: 30px;
}

@media (max-width: 576px){
	.logo{
		margin-left: 2vw;
	}

  }


  .inactive {
	color: var(--secondaryColor); 
	pointer-events: none;
  }