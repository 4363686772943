.footer-social{
    height: 20vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.footer-social > a {
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
    color: rgb(56, 56, 56);
}

.footer-social > a:hover{
    color: black;
    font-size: 22px;
}

.footer-copy{
    width: 100vw;
    font-size: 13px;
    color: rgb(56, 56, 56);
}

.footer-copy > p > a{
    text-decoration: none;
    color: rgb(56, 56, 56);
}

.footer-copy > p > a:hover{
    text-decoration: none;
    color: black;
}

footer{
    margin-top: 5vh;
}