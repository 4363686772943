.hero-container{
    width: 100vw;
    height: 80vh;
    overflow: hidden;
    margin-bottom: 40px;
}


.hero-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
}